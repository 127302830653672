import React from 'react'
import { Tooltip } from '@material-ui/core';

import logo from '../assets/logo.png';
import UserMenu from './UserMenu.jsx'

import { signInWithGoogle } from '../utils/firebaseUtils'

class Nav extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      currentUser: this.props.currentUser
    }
  }

  componentDidMount(){
    this.setState({currentUser: this.props.currentUser})
  }

  componentDidUpdate(prevProps){
    // console.log('this.props.currentUser, prevProps.currentUser', this.props.currentUser, prevProps.currentUser, this.props.currentUser!==prevProps.currentUser)
    return prevProps.currentUser !== this.props.currentUser
      ? this.setState({currentUser: this.props.currentUser})
      : null
  }

  shouldComponentUpdate(nextState){
    // console.log('this.state.currentUser, nextState.currentUser', this.state.currentUser, nextState.currentUser, !!nextState.currentUser, this.state.currentUser===nextState.currentUser)
    if(!!this.state.currentUser && !!nextState.currentUser && this.state.currentUser===nextState.currentUser){
      return false}
      else {return true}
  }

  render(){
    const { onAboutClick, onSignOut, setReactGAClickEvent } = this.props;
    const { currentUser } = this.state;
    // console.log('Nav component render')

    // console.log('currentUser', !!currentUser, currentUser)
    return(
      <nav className="nav">
        <div className="nav-content">
          <a href="/">
              <img src={logo} className="logo" alt="logo"/>
              <p>PomodoroTech</p>
          </a>
          <div className="nav-links">
            <a>
              <button className="nav-button" onClick={onAboutClick}>About</button>
            </a>
          {currentUser 
            ? <div className = "sign-out">
                <UserMenu 
                  userImageURL = {currentUser.photoURL}
                  setReactGAClickEvent= {setReactGAClickEvent}
                  signOut = {onSignOut}
                />
              </div>
            : <Tooltip title="Sign in to access tasks across devices and view your stats">
                <a onClick={()=>setReactGAClickEvent("sign in")}>
                  <button className="nav-button" onClick={signInWithGoogle}>Sign In</button>
                </a>
              </Tooltip>
          }
          </div>
        </div>
        <hr/>
      </nav>
    )
  }
}

export default Nav