import React from 'react'

const Footer = React.memo(({ onContactClick }) => {
    // console.log('Footer component render')
    return (
        <div id="footer">
            <hr className="footer-hr" />

            <div className="copyright">
                <a
                    href="mailto:pomodoropal@gmail.com?subject=Regarding PomodoroTech"
                    style={{textDecoration: "none", display: "block", color: "hsl(205, 59%, 52%)", fontWeight: "500"}}
                    onClick={onContactClick}
                >
                    Contact
                </a>
                <a href="#" style={{color: "hsl(205, 59%, 52%)", fontWeight: "500", textDecoration: "none" }}>PomodoroTech</a> © 2020
            </div>
        </div>
    )
})

export default Footer